.nav-container{
    display: flex;

}
.container{
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}
.nav-container.mobile{
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    position: absolute;
    align-items: start;
    z-index: 9999;
    background-color: var(--color-yellow);
}
.nav-container.mobile.hidden{
    display: none;
    width: 100%;
    height: 100vh;
    position: absolute;
    align-items: start;
}
.nav-links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    list-style-type: none;
}
.nav-links li a{
    font-family: raleway;
    text-decoration: none;
    color: rgb(34, 34, 34);
    font-size: 15px;
}
.nav-links li{
    padding: 0 10px;
}

.toggler{
    width: 40px;
    position: absolute;
    right: 25px;
    z-index: 99;
    top: 35px;
    z-index: 9999;
}
.nav-links-mobile{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
    margin-top: 170px;
    list-style-type: none;
}
.nav-links-mobile li a {
    text-decoration: none;
    color: var(--color-green);
    font-family: raleway;
    font-size: 30px;
    text-align: left;
    font-weight: 400;
}
.book-cta{
    background-color: var(--color-yellow);
    border: none;
    padding: 3px 40px;
    height: 40px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-decoration: none;
}
.book-cta.mobile{
    background-color: var(--color-green);
    border: none;
    padding: 1px 30px;
    height: 60px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    font-weight: 200;
    cursor: pointer;
    font-weight: 500;
}
.logo{
    width: 90px;
    margin: 10px 0 0 10px;

}
.nav-item{
    text-decoration: none;
    color: var(--color-green) ;
}