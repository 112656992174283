.footer-box{
    background-color: var(--color-green);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
}
.footer-container{
    background-color: var(--color-green);
    padding: 50px 0;
 
}
.col-2{
    text-align: left;
    justify-self: center;
    width: 25%;
    
}
.phone-number span{
    display: block;
    font-weight: 700;
}

.footer-adress{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
}

.col-2 p{
   
    line-height: 25px;
    margin-top: 10px;
    font-weight: 200;
    font-size: 14px;
}
.quick-links ul{
    margin-top: 20px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
}
.quick-links ul li a{
    color: #fff;
    text-decoration: none;
    text-align: left;
    font-size: 14px;
    font-weight: 200;
}
.social-media{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
}
.f-adress{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 15px;
    font-weight: 200;
}
.footer-divider{
    width: 90%;
    height: 1px;
    background-color: rgba(208, 208, 208, 0.357);
    margin: 0 auto;
}
.copyright-box{
    max-width: 1200px;
    margin: 20px auto;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 200;
    text-align: start;
}
.copyright-box span{
    font-weight: 600;
}
@media only screen and (max-width: 768px){
    .footer-box{
        background-color: var(--color-green);
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 50px;
        grid-area: 50px;
    }
    .phone-number{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .col-2{
        width: 70%;
        text-align: center;
        margin-top: 30px;
    }
    .quick-links ul{
        align-items: center;
    }
    .quick-links{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .social-media{
        justify-content: center;
        align-items: center;
        margin: 30px 0;
    }
    .footer-adress{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 30px 0;
    }
    .f-adress{
        align-items: center;
    }
    .copyright-box{
        flex-direction: column;
        gap: 30px;
    }
    
}

