.outlets-container{
    font-family: raleway;
}

.outlets-container h1{
    font-size: 45px;
    color: var(--color-green);
    margin: 50px 0;
}
.outlet-left h2{
    font-size: 35px;
    color: var(--color-green);
    margin-bottom: 30px;
}
.outlet-left p{
    font-size: 20px;
    margin-top: -15px;
    font-weight: 200;
    margin-bottom: 20px;
}
.outlets-main{
    width: 100%;
    margin-top: 140px;
    font-family: raleway;
    padding: 0px 0px 0px;
}
.outlet-box:nth-child(odd){
    flex-direction: row-reverse;
}
.alltheway{
    background-color: #ECECEC;
}
.outlet-box:nth-child(odd) .outlet-right img{
    left: 0;
}
.outlet-box{
    max-width: 1200px;
    margin: 200px auto;
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
    padding: 30px 50px 0 0;
}
.outlet-left{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: left;
}
.outlet-right{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;

}
.outlet-right img{
    object-fit: cover;
    width: 45%;
    position: absolute;
    bottom: 0;
    right: 0;
}
.outlet-item{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    padding: 5px 0;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
}

.outlet-left ul{
    padding: 15px 0;
    margin-bottom: 30px;
}

@media only screen and (max-width: 992px){
    .outlet-left h2{
        font-size: 26px;
    }
    .outlet-left p{
        font-size: 15px;
    }
    .outlet-item{
        font-size: 14px;
    }
    .outlet-item img{
        width: 20px;
    }
    .outlet-right img{
        width: 45%;
    }
    .outlet-box{
        padding: 0px 30px;
    }
}
@media only screen and (max-width: 768px){

    .outlet-right img{
        object-fit: cover;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    .outlet-right{
        width: 100%;
        height: 100%;
        position: relative;

    }
    .outlet-box{
        padding: 20px;
        margin: 40px 0 0 0 ;
        flex-direction: column;
    }
    .outlets-main{
        margin-bottom: 150px;
        margin-top: 70px;
    }
    .outlet-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        text-align: left;
    }
    .outlet-box:nth-child(odd){
        flex-direction: column;
    }
}