.contact-modal{
    position: fixed;
    bottom: 76px;
    right: 0;
    background-color: var(--color-green);
    z-index: 9999;
    color: #fff;
    margin: 0;
    padding: 0;
    border-radius: 50px 0 0 0;
}
.close-contact{
    width: 20px;
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
 
}
.modal-adress{
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.phone-number a{
    color: #fff;
    text-decoration: none;
}
.modal-phone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    text-decoration: none;
    font-family: Helvetica;
}
.modal-phone a{
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 23px;
}
.modal-links{
    display: flex;
    gap: 10px;   
}