.mini-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    width: 100%;
}
.icons{
    display: flex;
    gap: 20px;
}
.row-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 15px;
    padding: 30px 0;
    position: relative;
}
.row-1::after{
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
}
.row-1 h5{
    color: #fff;
    font-size: 22px;
    text-align: left;
}
.row-1 img{
    width: 100%;
}
.acom-wrapper{
    background-color: var(--color-green);
}
.acom-container{
    max-width: 1200px;
    padding: 40px;
    margin: 0 auto;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.col{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}
.col-6{
    
    background-color: var(--color-yellow);
    height: 150vh;
    padding: 20px;
    width: 300px;
    align-self: center;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.col-6 ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    color: #fff;
    list-style-type: none;
   font-weight: 600;
    text-align: center;
}
.col-6 h3{
    color: var(--color-green);
    text-align: center;
    font-size: 30px;
    margin-bottom: 50px;
}














.luxurious-container{
    position: relative;
    color: #fff;
    font-family: raleway;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.luxurious-bg{
    position: absolute;
    height: 70%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
}
.rooms{
    max-width: 1200px;
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    position: relative;
}
.room{
    position: relative;
}
.room-bg{
    object-fit: cover;
    width: 100%;
}
.room-details{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 60px;
    left: 50px;
    gap: 70px;
}
.room-details h3{
    font-size: 28px;
    text-align: left;
}
.view-rooms-cta{
    background-color: var(--color-yellow);
    border: none;
    padding: 9px 30px;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    text-decoration: none;
}
.view-rooms-cta img{
    width: 30px;
}
.bar{
    width: 130px;
    height: 3px;
    background-color: #fff;
    margin: 20px auto;
}
.luxurious-header h1{
    font-size: 40px;
    margin-top: 70px;
    margin-bottom: 20px;
}
.luxurious-header p{
    font-weight: 200;
}
@media only screen and (max-width: 992px){
    .room-bg{
        width: 90%;
    }
    .room-details h3{
        font-size: 20px;
    }
    
}
@media only screen and (max-width: 768px){
    .acom-container{
        max-width: 1200px;
        padding: 40px;
        margin: 0 auto;
        gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }
    .col-6{
        order: 1;
        margin: 0 auto;
        height: 100vh;
    }

    .rooms{
        max-width: 1200px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        position: relative;
    }
    .room-bg{
        width: 90%;
    }
    .room-details h3{
        padding: 0 0 0 20px;
    }
    .view-rooms-cta{
        padding: 10px 10px;
        margin-left: -30px;
    }
    .luxurious-bg{
        height: 100%;
    }
}