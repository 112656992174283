.container{
    width: 100%;
}
.hero-wrapper{
    width: 100%;
    margin: 0px 0;
}
.watch-header{
    margin-bottom: 20px;
}
.video-links{
    width: 100%;
    height: 160px;
    border-top: 1px solid #fff;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}
.tour-cta{
    position: absolute;
    top: -20px;
    left: 40%;
    margin-left: -150px;
    width: 300px;
}
.tour-cta a{
    background-color: var(--color-yellow);
    border: none;
    padding: 15px 40px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    
}
.videos{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.videos a{
    cursor: pointer;
    width: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.play-icon{
    display: block;
    width: 15%;
    border: 1px solid var(--color-yellow);
    padding: 15px 50px;
}
.hero-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    font-family: Raleway;
    color: #fff;
    height: 120vh;
    gap: 0;
}   

.hero-mobile-container{
    overflow: hidden;
    margin-top: 20px;
    position: relative;
    margin-bottom: 50px;
}

.hero-mobile-img{
    object-fit: cover;
    width: 100%;
    object-position: center;
    height: 40vh;
    margin: 0;
    padding: 0;
}
.mobile-header{
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    margin: 0;
}
.placeholder-img{
    object-fit: cover;
    width: 130%;
    height: 270px;
    position: absolute;
    left: 0;
    z-index: -1;    
    padding: 0;

}
  
.hero-mobile-details{
    position: absolute;
    height: 200px;
    width: 200px;
    top: 60px;
    left: 50%;
    margin-left: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 15px;
}
.hero-mobile-details h1{
    font-size: 40px;
    width: 100%;
}
.hero-mobile-details p{
    font-size: 20px;
}

.hero-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.click-cta{
    background-color: var(--color-yellow);
    border: none;
    padding: 12px 35px;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-decoration: none;
}
.hero-left{
    width: 50%;
}
.hero-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 0;
    padding: 0;
    height: 100%;
    color: #034247;

}

.hero-mobile-right{
    background-color: var(--color-green);
    color: #fff;
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
}

.mobile-adress{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
}
.hero-left h1{
    font-size: 50px;
    width: 60%;
    text-align: start;
}

.hero-header{
    font-size: 60px;
    width: 70%;
    text-align: start;
    line-height: 60px;
    margin-left: 20px;
    padding-top: 70px;
}
.hero-details{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 30px;

}
.mobile-number{
    display: flex;
    gap: 20px;
}
.phone-number{
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
    font-weight: 200;
}
.social{
    display: flex;
    gap: 20px;
    justify-content: start;
    align-items: center;
    color: var(--color-yellow);
}
.social-links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #034247;
}
.video{
    background-color: var(--color-yellow);
    width: 80%;
    height: 50%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -3;
}
.hero-adress{
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    margin-left: 40px;
    
}
.right-bottom{
    background-color: #D7DDDD;
    width: 100%;
    height: 50%;
    position: relative;
}
.hero-adress h1{
    text-align: start;
}
iframe{
    object-fit: cover;
    width: 100%;
}
@media only screen and (max-width: 992px){
    .hero-img{

    }
}
@media only screen and (min-width: 1300px){
  .hero-header{
    font-size: 70px;
    width: 60%;
  }
  .hero-adress{
    margin-left: 100px;
  }
}
@media only screen and (max-width: 768px){
    .hero-img{

    }
    .video{
        display: none;
    }
    .hero-container{
        height: 70vh;
        position: relative;
        overflow-x: hidden;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        z-index: -2;
    }
    .hero-left{
        padding: 0;
        margin: 0;
    }
    .hero-right{
        padding: 0;
        margin: 0;
    }
}