.form-container{
    font-family: raleway;

}
.form-container h1{
    width: 50%;
    text-align: center;
    margin: 50px auto;
    font-weight: 300;
}
form{
    max-width: 1200px;
    margin: 100px auto;
    display: flex;

    align-items: start;
    justify-content: space-between;
}
.left-form{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
label{
    display: block;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
}
input{
    width: 450px;
    height: 50px;
    padding: 0 0 0 10px;
    margin-bottom: 20px;
    font-size: 18px;
}
textarea{
    width: 450px;
    padding: 7px;
}
.right-form{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 25px;
}

::placeholder{
    
    font-size: 18px;
    font-family: raleway;
}
.radio-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.radio-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}
.radio-btns input{
    width: 25px;
    margin-right: 10px;
    
}
.radio-btns input:checked{
    background-color: var(--color-yellow);
    color: var(--color-yellow);
}
.radio-btns .col{

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
}
.col label{
    display: inline;
    margin-bottom: 17px;
}
.radio-box h3{
    text-align: left;
    font-weight: 300;
    font-size: 16px;
}
.submit-cta{
    background-color: var(--color-yellow);
    width: 465px;
    height: 55px;
    padding: 10px;
    font-size: 18px;
    border: none;
    color: #fff;
}
@media only screen and (max-width: 768px){
    form{
        max-width: 1200px;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding: 20px;
        gap: 20px;
    }
    .form-container h1{
        width: 80%;
        text-align: center;
        margin: 50px auto;
        font-weight: 300;
        font-size: 25px;
    }
    label{
        font-size: 17px;
    }
    input{
        width: 350px;
        border: 1px solid var(--color-yellow);
    }
    textarea{
        width: 350px;
        border: 1px solid var(--color-yellow);
    }

    .submit-cta{
        background-color: var(--color-yellow);
        width: 362px;
        height: 55px;
        padding: 10px;
        font-size: 18px;
        border: none;
        color: #fff;
    }
    .radio-box h3{
        text-align: left;
        font-weight: 300;
        font-size: 16px;
        margin-top: -10px;
    }

}