.map-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 350px;
}
.blog{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    color: #fff;
    padding: 0 0 8rem 5rem;
    gap: 30px;
}
.blog h3{
    font-size: 30px;
}
.read-blog-cta{
    background-color: var(--color-yellow);
    border: none;
    padding: 9px 30px;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    text-decoration: none;
}
.read-blog-cta a{
    text-decoration: none;
    color: #fff;
}
.blog img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
@media only screen and (max-width: 768px){
    .map-container{
        flex-direction: column-reverse;
        height: 500px;
    }
    .map-item{
        height: 400px;
        display: block;
    }
    .blog{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: start;
        color: #fff;
        padding: 0 0 2rem 5rem;
        gap: 10px;
    }
    .blog h3{
        font-size: 20px;
    }
}