.meeting-container{
    background-color: #ECECEC;
    font-family: raleway;
    padding-bottom: 100px;
   
}
.meeting-box{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.meeting-img{
    width: 100%;
    margin-top: -70px;
}
.meeting-details{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    gap: 40px;
}
.meeting-room ul{
    padding: 0;
    color: #034247;
}
.meeting-room h2{
    color: #034247;
    font-size: 35px;
    margin-bottom: 20px;
}
.meeting-room p{
    color: #034247;
    font-size: 20px;
    margin-top: -15px;
    font-weight: 200;
    margin-bottom: 20px;
}
@media only screen and (max-width: 768px){
    .meeting-details{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        padding: 20px;
    }
    .meeting-room h2{
        color: #034247;
        font-size: 30px;
    }
    .meeting-room p{
        color: #034247;
        font-size: 18px;
        margin-top: -15px;
        font-weight: 200;
    }
    .meeting-room ul li{
        font-size: 15px;
    }
}