
.swiper {
    width: 100px;
    height: 100px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 100%;
   
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .roomSwiper2 {
    margin-top: 0px;
    height: 100%;
    width: 50%;
    position: absolute;
  }
  
  .mySwiper {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .roomSwiper2 img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.rooms-bg{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #202020;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
}
.rooms-container{
    position: relative;

}

.book-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.book-box .view-rooms-cta{
    text-decoration: none;
}
.rooms-header{
    position: absolute;
    z-index: 1;
    top: 150px;
   left: 50%;
   margin-left: -300px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 30px;
    width: 600px;
    color: #fff;
}


.rooms-header h1{
    font-size: 60px;
}
.rooms-header p{
    line-height: 25px;
    font-weight: 200;
}
.room-bar{
    width: 100%;
    height: 1px;
    background-color: var(--color-green);
}
@media only screen and (max-width: 768px){
  .roomSwiper2{
    position: relative;
    width: 100%;
  }
    .rooms-header{
        position: absolute;
        z-index: 1;
        top: 100px;
       left: 50%;
       margin-left: -200px;
        width: 400px;
        color: #fff;
    }
    .rooms-header h1{
        font-size: 50px;
    }
    .rooms-header p{
        line-height: 20px;
        font-weight: 200;
    }
    .rooms-bg{
        width: 100%;
        height: 80vh;
    
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: -1;
    }
}