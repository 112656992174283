.facilty-container{
    font-family: raleway;
    max-width: 1200px;
    margin: 50px auto;
}

.facilty-container span{
    font-weight: 600;
}
.facility-item-container{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    color: var(--color-yellow);
    gap: 15px;
    
}
.facility-item img{
    width: 40px;
    margin: 0 auto;
}
.facility-item{
    background-color: #FAFAFA;
    height: 100px;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.item-name{
    font-size: 15px;
}
.facilty-container h1{
    margin-bottom: 20px;
    color: var(--color-green);
}
@media only screen and (max-width: 768px){

    .facility-item-container{
        grid-template-columns: repeat(2, 1fr);
    }
}