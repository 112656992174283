.ad-banner img{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}
.ad-banner{
    position: fixed;
    z-index: 999;
    bottom: 15px;
    margin: 0 auto;
    gap: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  
}

.contact-btn{
    position: fixed;
    bottom: 80px;
    right: 0;
    z-index: 99;
  
}
.contact-btn button{
background-color: var(--color-yellow);
border: none;
padding: 15px 30px;
border-radius: 20px 30px 0 30px;
color: #fff;
cursor: pointer;
}
.package-name{
    animation: swipe var(--speed) linear infinite backwards;
    display: flex;
    position: relative;
    z-index: 9;
    width: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    margin-top: 14px;
    color: #fff;
    font-family: Raleway;
    text-align: left;
    text-transform: uppercase;
}
@keyframes swipe {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(100%);
    }
  }
  