* {
  margin: 0;
  padding: 0;
  border-radius: border-box;
  scroll-behavior: smooth;
}

:root {
  --color-yellow: #EEAF28;
  --color-green: #034247;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap')

